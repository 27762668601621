import AuthUtil from './authUtil'
// Import vue stuff
import Vue from 'vue'
import Router from 'vue-router'
// import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
// import Login from '@/views/Login'
// import Auth from '@/views/Auth'
// import Signup from '@/views/Signup'
// import Pricing from '@/views/Pricing'
import Privacy from '@/views/Privacy'
import Terms from '@/views/Terms'
// import MonitoringServicePricing from '@/views/MonitoringServicePricing'
// import ComparisonServicePricing from '@/views/ComparisonServicePricing'
// import PricingCombined from '@/views/PricingCombined'
// import PricingMetered from '@/views/PricingMetered'
// import DocumentationIndex from '@/views/DocumentationIndex'
import NotFound from '@/views/404.vue'
import Forbidden from '@/views/403.vue'
import Error from '@/views/Error.vue'

import * as UserTypes from './store/types/user'

import store from './store' // your vuex store 


// import Constants from './constants.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // Public routes
    {
      path: '/',
      name: 'Home',
      component: About
    },
    // {
    //   path: '/signup',
    //   name: 'Signup',
    //   component: Signup
    // },
    {
      path: '/register/beta',
      name: 'Register',
      component: () => import(/* webpackChunkName: "register" */ '@/views/RegisterEcommerce.vue'),
      meta: {
        noNav: true
      }
    },
    {
      path: '/register/demo',
      name: 'Demo',
      component: () => import(/* webpackChunkName: "register" */ '@/views/DemoRequest.vue'),
      meta: {
        noNav: true
      }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy,
      meta: {
        requiresDarkNav: true
      }
    },
    {
      path: '/terms',
      name: 'Terms',
      component: Terms,
      meta: {
        requiresDarkNav: true
      }
    },
    {
      path: '/auth',
      name: 'Auth',
      // component: Auth
      component: () => import(/* webpackChunkName: "pikle-auth" */ '@/views/Auth.vue'),
    },
    {
      path: '/forbidden',
      component: Forbidden
    },
    {
      path: '/error',
      component: Error
    },
    // Temp private pricing routes
    {
      path: '/pricing',
      name: 'Pricing',
      component: () => import(/* webpackChunkName: "pricing" */ './views/Pricing.vue'),
      // component: Pricing,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/pricing/comparison',
      name: 'Comparison Service Pricing',
      // component: ComparisonServicePricing,
      component: () => import(/* webpackChunkName: "pricing" */ './views/ComparisonServicePricing.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/pricing/monitoring',
      name: 'Market Insights Pricing',
      // component: MonitoringServicePricing,
      component: () => import(/* webpackChunkName: "pricing" */ './views/MonitoringServicePricing.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/pricing/combined',
      name: 'Combined Service Pricing',
      // component: PricingCombined,
      component: () => import(/* webpackChunkName: "pricing" */ './views/PricingCombined.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/pricing/metered',
      name: 'Metered Service Pricing',
      // component: PricingMetered,
      component: () => import(/* webpackChunkName: "pricing" */ './views/PricingMetered.vue'),
      meta: {
        requiresAuth: true
      }
    },
    // Console
    {
      path: '/console',
      name: 'Console',
      component: () => import(/* webpackChunkName: "console" */ './views/Console.vue'),
      meta: {
        requiresAuth: true
      }
    },
    // Admin
    {
      path: '/admin/apps',
      name: 'System admin apps index',
      component: () => import(/* webpackChunkName: "admin" */ './views/SysAdminApps.vue'),
      meta: {
        requiresAuth: true,
        requiresSysAdmin: true
      }
    },
    {
      path: '/admin/apps/:appId',
      name: 'System admin app overview',
      component: () => import(/* webpackChunkName: "admin" */ './views/SysAdminAppOverview.vue'),
      meta: {
        requiresAuth: true,
        requiresSysAdmin: true
      }
    },
    {
      path: '/admin/apps/:appId/insightgaps',
      name: 'System admin app insight gaps',
      component: () => import(/* webpackChunkName: "admin" */ './views/Insights/SysAdminAppInsightGaps.vue'),
      meta: {
        requiresAuth: true,
        requiresSysAdmin: true
      }
    },
    // App routes
    {
      path: '/apps/:appId',
      name: 'App',
      // route level code-splitting
      // this generates a separate chunk (pikle-apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/ConfigureApp.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/docs/taxons',
      name: 'Taxon Documentation',
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/TaxonSetupDocs.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/comparison/:taxonId',
      name: 'AppComparisonTaxonOverview',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Comparison/ComparisonTaxonDash.vue'),
      meta: {
        requiresAuth: true,
        serviceId: 'comparison'
      }
    },
    {
      path: '/apps/:appId/comparison/:taxonId/catalog',
      name: 'AppComparisonTaxonCatalog',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Content/ContentTaxonEditor.vue'),
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Categories/TaxonCatalog.vue'),
      meta: {
        requiresAuth: true,
        serviceId: 'comparison'
      }
    },
    {
      path: '/apps/:appId/comparison/:taxonId/catalog/groups/:groupId',
      name: 'AppComparisonTaxonCatalogGrouped',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Categories/TaxonCatalog.vue'),
      meta: {
        requiresAuth: true,
        serviceId: 'comparison'
      }
    },
    {
      path: '/apps/:appId/comparison/:taxonId/features',
      name: 'AppComparisonTaxonFeatures',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Categories/TaxonFeatures.vue'),
      meta: {
        requiresAuth: true,
        serviceId: 'comparison'
      }
    },
    {
      path: '/apps/:appId/categories/:taxonId/training',
      name: 'AppTaxonTraining',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Comparison/ComparisonTaxonTraining.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/categories/:taxonId/features',
      name: 'AppTaxonFeatures',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Categories/TaxonFeatures.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/categories/:taxonId/settings',
      name: 'AppTaxonContentSettings',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Comparison/ComparisonTaxonContentSettings.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/categories/:taxonId/catalog/items/:itemId',
      name: 'AppTaxonItemEditor',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Content/ContentItemEditor.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/categories/:taxonId/catalog/groups/:groupId',
      name: 'AppTaxonCatalogGrouped',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Categories/TaxonCatalog.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/comparison/overview',
      name: 'AppComparison',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Comparison/ComparisonOverview.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/integrate',
      name: 'AppIntegration',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Comparison/Integrate.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/integrate/secrets',
      name: 'AppSecrets',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Settings/AppSecretsOverview.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/catalog',
      name: 'AppCatalog',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Content/AppCatalog.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/taxonomy',
      name: 'AppPikleCategories',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Taxonomy/TaxonomyIndex.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/taxonomy/:taxonId',
      name: 'AppTaxonOverview',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Taxonomy/TaxonEditor.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/taxonomy/:taxonId/collections/:groupId',
      name: 'AppTaxonGroupedOverview',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Taxonomy/TaxonEditor.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/categories/:taxonId/catalog',
      name: 'AppPikleCategoryEditor',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Categories/TaxonCatalog.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/comparison/analytics',
      name: 'AppComparisonAnalytics',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Comparison/Analytics.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/leads/overview',
      name: 'AppLeads',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Leads/LeadsOverview.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/leads/analytics',
      name: 'AppLeadsAnalytics',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "apps" */ './views/Leads/Analytics.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/search',
      name: 'AppSearch',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Search/SearchOverview.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/monitoring/overview',
      name: 'AppMarketInsights',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Insights/InsightsOverview.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/filtering/overview',
      name: 'AppFiltering',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Filtering/Overview.vue'),
      meta: {
        requiresAuth: true,
        serviceId: 'filtering'
      }
    },
    {
      path: '/apps/:appId/filtering/:taxonId',
      name: 'AppFilteringTaxonOverview',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Filtering/FilteringTaxonDash.vue'),
      meta: {
        requiresAuth: true,
        serviceId: 'filtering'
      }
    },
    {
      path: '/apps/:appId/filtering/:taxonId/catalog',
      name: 'AppFilteringTaxonCatalog',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Content/ContentTaxonEditor.vue'),
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Categories/TaxonCatalog.vue'),
      meta: {
        requiresAuth: true,
        serviceId: 'filtering'
      }
    },
    {
      path: '/apps/:appId/filtering/:taxonId/catalog/groups/:groupId',
      name: 'AppFilteringTaxonCatalogGrouped',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Categories/TaxonCatalog.vue'),
      meta: {
        requiresAuth: true,
        serviceId: 'filtering'
      }
    },
    {
      path: '/apps/:appId/filtering/:taxonId/features',
      name: 'AppFilteringTaxonFeatures',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Categories/TaxonFeatures.vue'),
      meta: {
        requiresAuth: true,
        serviceId: 'filtering'
      }
    },
    {
      path: '/apps/:appId/filtering/analytics',
      name: 'AppFilteringAnalytics',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Filtering/Analytics.vue'),
      meta: {
        requiresAuth: true,
        serviceId: 'filtering'
      }
    },
    {
      path: '/apps/:appId/webhooks/overview',
      name: 'AppWebhooks',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/wip.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/assistant',
      name: 'AppAssistant',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Assistants/PlayGround.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/settings',
      name: 'AppSettings',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Settings/SettingsOverview.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apps/:appId/settings/billing',
      name: 'AppBilling',
      // route level code-splitting
      // this generates a separate chunk (apps.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "pikle-apps" */ './views/Settings/AppBillingOverview.vue'),
      meta: {
        requiresAuth: true
      }
    },
    // App docs
    {
      path: '/apps/:appId/docs',
      name: 'Documentation',
      // component: DocumentationIndex,
      component: () => import(/* webpackChunkName: "docs" */ './views/DocumentationIndex.vue'),
      meta: {
        requiresAuth: true
      }
    },
    // Catch all route
    {
      path: '*',
      component: NotFound
    }
  ]
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  
  const currentUser = AuthUtil.auth.currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresSysAdmin = to.matched.some(record => record.meta.requiresSysAdmin)

  if (currentUser && !store.state.user.loaded) {
    // Load user details to check if they are marked as a developer
    store.dispatch(`user/${UserTypes.ACTION_LOAD_PREFERENCES}`)
  }
  // Require verified users
  // TODO implement email verification
  // if (requiresAuth && (!currentUser || !currentUser.emailVerified)) {
  if (requiresAuth) {
    // const forwardPath = window.location.pathname
    // next({ name: 'Login', query: { from: forwardPath, failedVerification: (currentUser ? !currentUser.emailVerified : false) } })

    // Initialize user store regardless of email verified status.
    if (currentUser && !store.state.user.loaded) {
      store.dispatch(`user/${UserTypes.ACTION_LOAD_PREFERENCES}`).then(prefs => {
        if (requiresSysAdmin && (!prefs || !prefs.isSysAdmin)) {
          router.replace('/')
        }
      })
    }

    if (currentUser && currentUser.emailVerified) {
      next()
    } else  {
      // const forwardPath = window.location.pathname
      // next({ name: 'Login', query: { from: forwardPath, failedVerification: (currentUser ? !currentUser.emailVerified : false) } })
      // const pikleDomain = 'http://localhost:8180'
      // window.location.assign(`${Constants.PIKLE_DOMAIN}/developer`)
      next({ name: 'Home'})
    }
  } else {
    next()
  }
})

export default router
